  .subtitle {
    text-align: center;
    background-color: #f7ca37;
    padding: 30px 0px;
  }
    .subtitle > h4 {
      margin: 0;
      margin-top: -15px;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
    }
  

  .recipe-container {
    margin-top: 30px;
    padding: 40px 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

    .recipe {
      position: relative;
      background-color: wheat;
      width: calc(25% - 20px); /* Adjusted to fit four in a row with spacing */
      height: 200px;
      margin-bottom: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
      transition: background-size 0.3s ease-in-out, filter 0.3s ease-in-out;
      box-shadow: inset 0 0 0 100vmax rgba(0,0,0,.3);
    }
     .recipe > p {
        font-style: normal;
        font-weight: 700;
        color: white;
        text-align: center;
        padding-top: 4px;
      }

      .recipe > button {
        border: none;
        height: 30px;
        background-color: #A060CC;
        font-style: normal;
        font-weight: 600;
        cursor: pointer;
      }

      .paginationWrapper{
        display: flex;
        justify-content: center;
        margin: 20px 0;
        width: 100%;
        justify-content: flex-end;
      }
      .paginationWrapper > button {
        margin: 0 5px;
        padding: 10px 15px;
        border: 1px solid #ccc;
        background-color: #f4f4f4;
        cursor: pointer;
      }
    
      .paginationWrapper > .active {
        background-color: #A060CC;
        border-color: #A060CC;
      }
    
      .paginationWrapper > button:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    
  
    @media screen and (max-width: 768px) {
        .recipe-container{
        padding: 40px 50px;
        }
        .recipe{
            width: calc(35% - 20px);

        }
      }
       @media screen and (max-width: 600px) {
        .recipe-container{
        padding: 40px 20px;
    }
    .recipe{
        width: calc(100% - 10px);

    }
      }

 