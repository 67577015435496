/* Add your desired styling here

.responsive-table-container {
    overflow-x: auto !important;
  }
  
  table {
    width: 100% !important;
    border-collapse: collapse !important;
    margin-bottom: 20px !important;
    table-layout: fixed;

  }
  
  th, td {
    padding: 10px !important;
    text-align: center !important;
    border-bottom: 1px solid #ddd !important;
  }
  
  @media screen and (max-width: 600px) {
    .responsive-table-container {
      overflow-x: auto !important;
    }
    th, td {
      display: block !important;
      width: 100% !important;
      box-sizing: border-box !important;
    }
  }
   */

   .rdt_TableRow:nth-child(even) {
    background-color: #f2f2f2;
    
  }
  .jJhCqB div:first-child{
    white-space: normal !important;
  }
