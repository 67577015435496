/* <style
      data-vue-ssr-id="07eed55a:0 07eed55a:1 312f56c1:0 5eb93272:0 46f23c9d:0 3bf95a53:0 1be9ba3e:0 3f6f130b:0 7c17cb42:0 832d382c:0"
    > */
      @import url(https://fonts.googleapis.com/css2?family=Prompt:wght@300;400;500;600;700&amp;display=swap);
      :root {
        --nyd-primary: #2cb674;
        --nyd-primary-fade: rgba(44, 182, 116, 0.7);
        --nyd-secondary: #A060CC !important;
        /* --nyd-secondary: #08384e; */
        --nyd-white: #fff;
        --nyd-dark: #080808;
        --nyd-grey-1: #bdbdbd;
        --nyd-grey-2: #7c7474;
        --nyd-grey-3: #f9fafb;
        --nyd-grey-4: #828282;
        --fw-light: 300;
        --fw-regular: 400;
        --fw-medium: 500;
        --fw-semibold: 600;
        --page-size: 1210px;
        --nyd-radius-1: 8px;
        --nyd-transition-1: 0.5s ease-in-out;
        --nyd-on-top: 10;
      }
      button:focus,
      input:focus,
      select:focus,
      textarea:focus {
        border-color: initial !important;
        box-shadow: none !important;
        outline: 0;
      }
      button:disabled {
        cursor: not-allowed;
        opacity: 0.4;
      }
      body {
        font-family: Prompt, sans-serif !important;
        font-size: 16px;
      }
      .fade-enter-active,
      .fade-leave-active {
        transition: opacity 0.4s ease-in-out;
      }
      .fade-enter,
      .fade-leave-to {
        opacity: 0;
      }
      .nyd-page-wrapper {
        margin: 0 auto;
        max-width: 1210px;
        max-width: var(--page-size);
        padding: 0.9375rem;
        width: 100%;
      }
      .nyd-link {
        color: #2cb674 !important;
        color: var(--nyd-primary) !important;
        font-size: 0.875rem;
        font-weight: 400;
        font-weight: var(--fw-regular);
      }
      .nyd-button,
      .nyd-link {
        align-items: center;
        display: inline-flex;
        justify-content: center;
        -webkit-text-decoration: none;
        text-decoration: none;
      }
      .nyd-button {
        background: 0 0;
        border: initial;
        border-radius: 8px;
        border-radius: var(--nyd-radius-1);
        cursor: pointer;
        font-size: 1rem;
        min-height: 48px;
        min-width: 140px;
        padding: 8px 33px;
        transition: 0.5s ease-in-out;
        transition: var(--nyd-transition-1);
      }
      .nyd-button.nyd-button--small {
        border-radius: 6px;
        font-size: 14px;
        min-height: 38px !important;
        padding: 12px 41px !important;
      }
      .nyd-button.nyd-button--primary {
        /* background: #2cb674; */
        background: var(--nyd-dark) !important;
        color: #fff;
        color: var(--nyd-white);
        position: relative;
      }
       .nyd-button.nyd-button--secondary {
        background: #08384e;
        background: var(--nyd-secondary);
        color: #fff;
        color: var(--nyd-white);
        position: relative;
      }
      .nyd-button.nyd-button--primary:hover {
        background: rgba(44, 182, 116, 0.7);
        background: var(--nyd-primary-fade);
      }
      .nyd-button.nyd-button--dark-outline {
        border: 1px solid #08384e;
        border: 1px solid var(--nyd-secondary);
        color: #08384e;
        color: var(--nyd-secondary);
      }
      .nyd-button.nyd-button--primary-outline {
        /* border: 1px solid #2cb674; */
        border: 1px solid var(--nyd-secondary) !important;
        /* color: #2cb674; */
        color: var(--nyd-dark) !important;
      } 
      .nyd-button.nyd-button--dark-outline {
        /* border: 1px solid #2cb674; */
        border: 1px solid var(--nyd-dark) !important;
        /* color: #2cb674; */
        color: var(--nyd-white) !important;
      }
      .nyd-icon-button {
        align-items: center;
        background: #2cb674;
        background: var(--nyd-primary);
        border: initial;
        border-radius: 8px;
        border-radius: var(--nyd-radius-1);
        color: #fff;
        color: var(--nyd-white);
        display: flex;
        font-size: 1.3rem;
        height: 40px;
        justify-content: center;
        width: 60px;
      }
      .nyd-button.nyd-button--disabled,
      .nyd-icon-button.nyd-button--disabled {
        cursor: not-allowed;
        opacity: 0.4;
      }
      .nyd__header {
        background: #08384e;
        background: var(--nyd-secondary);
        justify-content: center;
        min-height: 50px;
      }
      .nyd__header,
      .nyd__nav {
        align-items: center;
        display: flex;
        width: 100%;
      }
      .nyd__nav {
        justify-content: space-between;
      }
      .nyd__nav .nyd__nav-brand img {
        max-width: 200px;
      }
      .nyd__nav-links {
        list-style: none;
        margin: 0;
        padding: 0;
      }
      .nyd__nav-links.nyd__nav-links-mobile {
        display: flex;
        flex-direction: column;
      }
      .nyd__nav-links .nyd__nav-link-item,
      .nyd__nav-links .nyd__nav-link-item .nyd__nav-link {
        display: inline-block;
      }
      .nyd__nav-links .nyd__nav-link-item .nyd__nav-link {
        color: #fff;
        color: var(--nyd-white);
        font-size: 0.875rem;
        font-weight: 400;
        font-weight: var(--fw-regular);
        padding: 1rem;
        -webkit-text-decoration: none;
        text-decoration: none;
        transition: 0.5s ease-in-out;
        transition: var(--nyd-transition-1);
      }
      .nyd__nav-links .nyd__nav-link-item .nyd__nav-link.nuxt-link-exact-active,
      .nyd__nav-links .nyd__nav-link-item .nyd__nav-link:active,
      .nyd__nav-links .nyd__nav-link-item .nyd__nav-link:focus,
      .nyd__nav-links .nyd__nav-link-item .nyd__nav-link:hover {
        /* color: #2cb674;
        color: var(--nyd-primary); */
        color: var(--nyd-dark) !important;
      }
      .nyd__mobile-nav-toggle {
        align-items: center;
        /* background: rgba(44, 182, 116, 0.7); */
        background: black !important;
        /* background: var(--nyd-primary-fade); */
        border: initial;
        border-radius: 50%;
        color: #fff;
        color: var(--nyd-white);
        display: flex;
        font-size: 1.3rem;
        height: 40px;
        justify-content: center;
        width: 40px;
      }
      .nyd__mobile-nav {
        background: #08384e;
        background: var(--nyd-secondary);
        height: 100vh;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10;
        z-index: var(--nyd-on-top);
      }
      .nyd-dropdown .dropdown-toggle:after {
        display: none;
      }
      .nyd-dropdown .dropdown-toggle .nyd-dropdown-icon {
        display: inline-block;
        transition: 0.5s ease-in-out;
        transition: var(--nyd-transition-1);
      }
      .nyd-dropdown .dropdown-toggle.show .nyd-dropdown-icon {
        transform: rotate(180deg);
      }
      .nyd-dropdown .dropdown-menu {
        border: initial !important;
        padding: 1rem;
      }
      .nyd-dropdown .dropdown-menu .dropdown-item {
        color: #080808 !important;
        color: var(--nyd-dark) !important;
        font-size: 0.875rem;
      }
      .nyd-dropdown .dropdown-menu .dropdown-item:active {
        background: #2cb674 !important;
        background: var(--nyd-primary) !important;
        color: #fff !important;
        color: var(--nyd-white) !important;
      }
      .nyd-checkbox {
        cursor: pointer;
        display: block;
        font-size: 1rem;
        margin-bottom: 0.5rem;
        padding-left: 1.8rem;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
      }
      .nyd-checkbox input {
        cursor: pointer;
        height: 0;
        opacity: 0;
        position: absolute;
        width: 0;
      }
      .nyd-checkbox .checkmark {
        background-color: #eee;
        border-radius: 0.25rem;
        height: 20px;
        left: 0;
        position: absolute;
        top: 0.4rem;
        transition: 0.5s ease-in-out;
        width: 20px;
      }
      .nyd-checkbox:hover input ~ .checkmark {
        background-color: #ccc;
      }
      .nyd-checkbox input:checked ~ .checkmark {
        background-color: #2cb674;
        background-color: var(--nyd-primary);
      }
      .nyd-checkbox .checkmark:after {
        content: "";
        display: none;
        position: absolute;
      }
      .nyd-checkbox input:checked ~ .checkmark:after {
        display: block;
      }
      .nyd-checkbox .checkmark:after {
        border: solid #fff;
        border-width: 0 1px 1px 0;
        height: 10px;
        left: 8px;
        top: 3px;
        transform: rotate(45deg);
        width: 5px;
      }
      .nyd-input-group {
        border: 1px solid #080808;
        border: 1px solid var(--nyd-dark);
        border-radius: 8px;
        border-radius: var(--nyd-radius-1);
        padding: 0.6rem;
        transition: 0.5s ease-in-out;
        transition: var(--nyd-transition-1);
      }
      .nyd-input-group:focus-within {
        border-color: #2cb674;
        border-color: var(--nyd-primary);
      }
      .nyd-input-group .nyd-input {
        background: 0 0;
        border: initial;
        box-shadow: none;
        color: #080808;
        color: var(--nyd-dark);
        font-size: 1rem;
      }
      .nyd-input-group .nyd-input.nyd-input--small {
        font-size: 0.875rem;
      }
      .nyd-input.nyd-input--textarea {
        height: 120px;
        max-height: 120px;
      }
      .nyd-input .nyd-input-icon {
        display: block;
        -o-object-fit: contain;
        object-fit: contain;
        width: 20px;
      }
      .nyd-hero,
      .nyd-section {
        align-items: center;
        display: flex;
        justify-content: center;
        min-height: 50vh;
        width: 100%;
      }
      .nyd-section {
        padding: 3.5rem 0 !important;
      }
      .nyd-hero.nyd-hero--about,
      .nyd-hero.nyd-hero--home {
        min-height: 85vh;
      }
      .nyd-hero.nyd-hero--about {
        background: url("./assets/about.8945fed.png") 50% / cover no-repeat !important;
      }
      .nyd-hero .nyd-hero__image,
      .nyd-section .nyd-section__image {
        display: block;
        margin: 1rem auto;
        max-width: 700px;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
      }
      .nyd-section__image.nyd-section__image--small {
        max-width: 500px;
      }
      .nyd-section__image.nyd-section__image--smaller {
        max-width: 225px;
      }
      .nyd-h1 {
        font-size: 3.2rem;
      }
      .nyd-h2 {
        font-size: 2.8rem;
      }
      .nyd-h3 {
        font-size: 2rem;
      }
      .nyd-body {
        font-size: 1rem;
      }
      .nyd-body--large {
        font-size: 1.5rem;
      }
      .nyd-body--small {
        font-size: 0.875rem;
      }
      .nyd-body--smaller {
        font-size: 0.75rem;
      }
      @media (max-width: 768px) {
        .my-form-wrapper{
          width:88% !important
        }
        .complain-text{
          width:100%;
          text-align: center;
          padding: 0 20px;
        }
        .nyd-h1 {
          font-size: 2.2rem;
        }
        .nyd-h2 {
          font-size: 1.8rem;
        }
        .nyd-h3 {
          font-size: 1.4rem;
        }
        .nyd-body {
          font-size: 0.875rem;
        }
      }
      .text-is--dark {
        color: var(--nyd-dark) !important;
      }
      .text-is--grey-1 {
        color: var(--nyd-grey-1) !important;
      }
      .text-is--grey-2 {
        color: var(--nyd-grey-2) !important;
      }
      .text-is--grey-3 {
        color: var(--nyd-grey-3) !important;
      }
      .text-is--grey-4 {
        color: var(--nyd-grey-4) !important;
      }
      .text-is--white {
        color: var(--nyd-white) !important;
      }
      .text-is--primary {
        color: var(--nyd-primary) !important;
      }
      .text-is--secondary {
        color: var(--nyd-secondary) !important;
      }
      .text-is--semibold {
        font-weight: var(--fw-semibold) !important;
      }
      .text-is--medium {
        font-weight: var(--fw-medium) !important;
      }
      .text-is--regular {
        font-weight: var(--fw-regular) !important;
      }
      .text-is--light {
        font-weight: var(--fw-light) !important;
      }
      .bg--white {
        background: var(--nyd-white) !important;
      }
      .bg--dark {
        background: var(--nyd-dark) !important;
      }
      .bg--secondary {
        background: var(--nyd-secondary) !important;
      }
      .bg--primary {
        background: var(--nyd-primary) !important;
      }
      .cursor--pointer {
        cursor: pointer !important;
      }
      .item--disabled {
        cursor: not-allowed;
        opacity: 0.4;
      }
      .nyd-divider {
        background: #e1e6ed;
        height: 1px;
        margin: 1.4rem 0;
        width: 100%;
      }
      .ssr-carousel-back-button,
      .ssr-carousel-next-button {
        -webkit-tap-highlight-color: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: 0 0;
        border: none;
        color: inherit;
        cursor: pointer;
        display: inline-block;
        padding: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
      }
      .ssr-carousel-back-button {
        left: 2%;
      }
      .ssr-carousel-next-button {
        right: 2%;
      }
      .ssr-carousel-back-icon,
      .ssr-carousel-next-icon {
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 21px;
        display: inline-block;
        display: flex;
        height: 42px;
        justify-content: center;
        transition: opacity 0.2s;
        width: 42px;
      }
      [aria-disabled] > .ssr-carousel-back-icon,
      [aria-disabled] > .ssr-carousel-next-icon {
        cursor: default;
        opacity: 0.1;
      }
      :not([aria-disabled]) > .ssr-carousel-back-icon,
      :not([aria-disabled]) > .ssr-carousel-next-icon {
        opacity: 0.5;
      }
      @media (hover: hover) {
        :not([aria-disabled]) > .ssr-carousel-back-icon:hover,
        :not([aria-disabled]) > .ssr-carousel-next-icon:hover {
          opacity: 0.85;
        }
      }
      :not([aria-disabled]) > .ssr-carousel-back-icon.active,
      :not([aria-disabled]) > .ssr-carousel-back-icon:active,
      :not([aria-disabled]) > .ssr-carousel-next-icon.active,
      :not([aria-disabled]) > .ssr-carousel-next-icon:active {
        opacity: 1;
      }
      .ssr-carousel-back-icon:before,
      .ssr-carousel-next-icon:before {
        content: "";
        position: relative;
      }
      .ssr-carousel-back-icon:before {
        background: 0;
        border-color: transparent #fff transparent transparent;
        border-style: solid;
        border-width: 9px 12px 9px 0;
        height: 0;
        left: -2px;
        width: 0;
      }
      .ssr-carousel-next-icon:before {
        background: 0;
        border-color: transparent transparent transparent #fff;
        border-style: solid;
        border-width: 9px 0 9px 12px;
        height: 0;
        left: 2px;
        width: 0;
      }
      .ssr-carousel-dot-button {
        -webkit-tap-highlight-color: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: 0 0;
        border: none;
        color: inherit;
        cursor: pointer;
        display: inline-block;
        padding: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
      }
      .ssr-carousel-dots {
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }
      .ssr-carousel-dot-icon {
        border: 2px solid rgba(0, 0, 0, 0.7);
        border-radius: 6px;
        display: inline-block;
        height: 12px;
        margin-left: 4px;
        margin-right: 4px;
        transition: opacity 0.2s;
        width: 12px;
      }
      [aria-disabled] > .ssr-carousel-dot-icon {
        background: rgba(0, 0, 0, 0.7);
        cursor: default;
        opacity: 1;
      }
      :not([aria-disabled]) > .ssr-carousel-dot-icon {
        opacity: 0.5;
      }
      @media (hover: hover) {
        :not([aria-disabled]) > .ssr-carousel-dot-icon:hover {
          opacity: 0.85;
        }
      }
      :not([aria-disabled]) > .ssr-carousel-dot-icon.active,
      :not([aria-disabled]) > .ssr-carousel-dot-icon:active {
        opacity: 1;
      }
      .ssr-carousel-track {
        display: flex;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
      }
      .ssr-carousel-track.dragging {
        pointer-events: none;
      }
      .ssr-carousel-slide {
        flex-shrink: 0;
      }
      .ssr-carousel-mask.disabled .ssr-carousel-slide[aria-hidden="true"] {
        display: none;
      }
      .ssr-carousel {
        touch-action: pan-y;
      }
      .ssr-carousel-slides {
        position: relative;
      }
      .ssr-peek-values {
        position: absolute;
      }
      .ssr-carousel-mask {
        position: relative;
      }
      .ssr-carousel-mask:not(.no-mask) {
        overflow: hidden;
      }
      .ssr-carousel-mask:not(.disabled):not(.not-draggable) {
        cursor: grab;
      }
      .ssr-carousel-mask:not(.disabled):not(.not-draggable).pressing {
        cursor: grabbing;
      }
      .ssr-carousel-visually-hidden {
        clip: rect(0 0 0 0);
        border: 0;
        -webkit-clip-path: inset(50%);
        clip-path: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px;
      }
      .nuxt-progress {
        background-color: #000;
        height: 2px;
        left: 0;
        opacity: 1;
        position: fixed;
        right: 0;
        top: 0;
        transition: width 0.1s, opacity 0.4s;
        width: 0;
        z-index: 999999;
      }
      .nuxt-progress.nuxt-progress-notransition {
        transition: none;
      }
      .nuxt-progress-failed {
        background-color: red;
      }
      .nyd__mobile-nav .dropdown-menu {
        background: 0 0 !important;
        border-left: 2px solid var(--nyd-primary) !important;
        border-radius: 0 !important;
        min-width: 100% !important;
        position: relative !important;
        transform: none !important;
        transition: 0.6s ease-in-out;
      }
      .nyd__mobile-nav .dropdown-menu .dropdown-item {
        color: var(--nyd-white) !important;
      }
      .nyd__mobile-nav .nyd__nav-link-item .nyd__nav-link {
        padding: 1rem 0 !important;
      }
      .leadership-carousel {
        position: relative;
      }
      .leadership-carousel__button {
        background: 0 0;
        border: initial;
        color: var(--nyd-white);
        font-size: 2.8rem;
        padding: 0;
        position: absolute;
        top: 35%;
      }
      .leadership-carousel__button.leadership-carousel__button-prev {
        left: -0.5rem;
      }
      .leadership-carousel__button.leadership-carousel__button-next {
        right: -0.5rem;
      }
      .leadership-card .leadership-card-img {
        max-height: 540px;
        min-height: 440px;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
      }
      .__logo{
        width: 10%;
      }
      .terms-and-conditions{
        padding:20px 70px;
        text-align: left;
      }
      @media screen and (max-width: 767px) {
        .terms-and-conditions{
          padding: 20px;
        }
        .leadership-card .leadership-card-img {
          max-height: 340px;
          min-height: 280px;
        }
        .__logo{
          width: 21%;
        }
      }
      .leadership-card .leadership-card-name {
        color: var(--nyd-white);
        font-size: 2rem;
        font-weight: var(--fw-semibold);
      }
      .leadership-card .leadership-card-role {
        background: var(--nyd-white);
        color: var(--nyd-dark);
        font-size: 0.875rem;
        padding: 4px 8px;
        width: -moz-fit-content;
        width: fit-content;
      }
      .leadership-card-img-placeholder,
      .leadership-card-name-placeholder,
      .leadership-card-role-placeholder {
        background: var(--nyd-grey-1);
        opacity: 0.7;
      }
      .leadership-card-img-placeholder {
        height: 400px;
        width: 100%;
      }
      .leadership-card-name-placeholder,
      .leadership-card-role-placeholder {
        height: 30px;
      }
      .leadership-card-name-placeholder {
        margin: 1rem 0;
        width: 80%;
      }
      .leadership-card-role-placeholder {
        width: 50%;
      }
      .nyd__blog-card .nyd__blog-card-img {
        border-radius: var(--nyd-radius-1);
        height: 270px;
        width: 100%;
      }
      .nyd__blog-card .nyd__blog-card-title {
        color: var(--nyd-dark);
        font-size: 1.5rem;
        font-weight: var(--fw-semibold);
        line-height: 32px;
      }
      .ssr-carousel-dots {
        margin-top: 4rem;
      }
      .ssr-carousel-dots .ssr-carousel-dot-icon {
        background: var(--nyd-grey-1);
        border: initial;
        height: 7px;
        transition: var(--nyd-transition-1);
        width: 24px;
      }
      [aria-disabled] > .ssr-carousel-dot-icon {
        background: var(--nyd-dark);
        width: 50px;
      }
      .nyd-cta {
        /* background: url("./assets/cast_vote.42bb9d8.svg") 50% / cover no-repeat; */
        border-radius: var(--nyd-radius-1);
        min-height: 350px;
        padding: 3rem 1rem;
        text-align: center;
        width: 100%;
      }
      .nyd-footer {
        align-items: center;
        display: flex;
        justify-content: center;
        min-height: 60vh;
        width: 100%;
      }
      .nyd-footer .nyd-footer__brand img {
        max-width: 130px;
      }
      .nyd-footer .nyd-footer__section-title {
        color: var(--nyd-white);
        font-size: 1.125rem;
        font-weight: var(--fw-semibold);
      }
      .nyd-footer .nyd-footer__link {
        color: var(--nyd-white);
        font-size: 1rem;
        font-weight: var(--fw-light);
        -webkit-text-decoration: none;
        text-decoration: none;
      }
      .nyd-footer .nyd-footer__socials .nyd-footer__social-link {
        display: inline-block;
        margin-right: 6px;
      }
      .nyd-footer .nyd-footer__socials .nyd-footer__social-link img {
        max-width: 24px;
      }
      .nyd-footer .nyd-footer__divider {
        background: var(--nyd-white);
        height: 1px;
        margin: 2.4rem 0;
        width: 100%;
      }

      .box_{
       border:2px solid black;
       height: 400px;
      }
      .my-btn{
        min-height: 30px !important;
        min-width: 100px !important;
        border-radius: 1 !important;
      }
      .footer_bg{
        background-image: url("./assets/bg_footer.png");
        background-size: cover;
        background-repeat: no-repeat;
      }

      .my-center-item{
        display: flex;
        justify-content: center;
        align-items: center;
      }
.input-margin-bottom{
  margin-bottom: 20px;
}
.__wrapper{
  background-image: url("./assets/giphy.webp");
  background-size: cover;
  background-repeat: no-repeat;
}
.my-ul{
  width: 100%;
  padding: 0 10px;
}
.show_side_bar {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  /* z-index: 99; */
  z-index: 1030;
  animation: showMenu 0.4s;
}

.my-link > a:hover{
color: #2cb674;
}
.my-ul > li{
  list-style: none;
  padding:10px 0;
  border-bottom: 1px solid #EAEAEA;
}

.close_side_bar {
  animation: showMenu 2s reverse;
}

@keyframes showMenu {
  from {
    left: -100%;
  }
  to {
    left: 0%;
  }
}

.hide_{
  display: none;
}

.show_{
  display: block;
}
.contact_box{
  background-color:white !important;
  height:60vh;
  border-radius:10px;
  width:40%;
  display:flex;
  flex-direction:column;
  align-content:center;
  padding:0 30px
}
.preview_box{
  background-color:white !important;
  height:80vh;
  overflow-y: scroll;
  border-radius:10px;
  width:40%;
  display:flex;
  flex-direction:column;
  align-content:center;
  padding:0 20px 30px
}


@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.slide-in-from-left {
  animation: slideInFromLeft 0.5s ease-in-out;
}

/* AboutUsPage.css */

.about-us-container {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  padding: 20px 85px;
  width: 100%;
  margin-top: 100px;
  min-height: 50vh;
}

.image-container {
  display: flex;
  justify-content: center;
  padding: 40px;
  max-height:50vh ;
  margin-top: 80px;
  /* background-color: white; */
  background: linear-gradient(315deg, #cacaca, #f0f0f0);
box-shadow:  -20px -20px 60px #bebebe,
             20px 20px 60px #ffffff;
  min-width: 50%; /* Adjust the image width as needed */
}
.image-wrapper{
  width:40%;
}

.image-container img {
  width: 100%;
  height: auto;
}

.text-container {
  flex: 1;
  min-width: 50%;
  padding: 0 20px; /* Adjust the padding as needed */
}
.compliance-welcome{
  font-size: 20px;
  padding: 20px 0;
}

.agent-wrapper{
  width:80%;
  padding:30px;
  display:flex;
  justify-content:center;
  align-items:center
}
.agent-writeup{
  display:flex;
  flex-direction:column;
  align-items:center;
  width:50%;
}

/* Add more styling as per your design preferences */
@media screen and (max-width: 767px) {
  .preview_box{
    width:90%;
  }
  .agent-writeup{
    width:100%
  }
  .agent-wrapper{
    width:100%
  }
  .about-us-container{
    flex-direction: column;
    padding:20px;
  }
  .text-container{
    width:100%;
    margin-top: 30px;
    padding: 0;
  }
  .image-container{
    width:100%;
    margin-top: 0;
  }
}
.social-icon {
  font-size: 24px;
  margin: 0 10px;
  /* color: #333;  */
    color: #ffff
}

.social-icon:hover {
  color: var( --nyd-secondary); /* Change the color on hover if desired */
}
.contact-card-wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 30px;
}

.contact-card{
  width:30%;
height: 50vh;
background-color: whitesmoke;
border-radius: 5px;
padding:20px;
border:0.8px solid var(--nyd-secondary);
}
.contact-card >h5{
  margin-top:20px;
}
@media screen and (max-width: 767px) {
  .contact_box {
    width:90%
  }
  .contact-card-wrapper{
    flex-direction: column;
    padding:0 20px;
  }
  .contact-card{
    width:100%;
    margin-bottom: 30px;
  }
}
.paystack-button{
  display: none !important;
}
.glass{
  /* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
}

