.sidebar {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 4rem;
    transition: all 300ms ease;
    width:20%;
    /* background: var(--nyd-secondary); */
    background: #A060CC;
    color: var(--nyd-white);
    min-height: 100vh;
  
   
  }
  /* logo */
  .bars{
    display: none;
  }
  
  .logo {
    display: flex;
    height: 5rem;
    font-weight: bold;
    font-size: 22px;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    height: 4%;
  }
  .logo > span > span {
    /* color: var(--pink); */
    color: var(--nyd-secondary);
  }
  
  .logo > img {
    width: 3rem;
    height: 3rem;
  }
  
  
  /* menu */
  .menu {
    margin-top: 4rem;
    padding-bottom:3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow-y: auto;
    overflow-x: hidden;


  }

/* ::-webkit-scrollbar {
     width: 0;  
    background: transparent; 
} */
  .menuItem {
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 2.5rem;
    margin-left: 2rem;
    position: relative;
    transition: all 300ms ease;
    border-radius: 0.7rem;
    font-size: 14px;
    text-decoration: none;
    color:white
  }
  
  .menuItem:hover {
    cursor: pointer;
    color: var(--nyd-primary);
  }
  
  .menu .menuItem:last-child {
    /* position: absolute; */
    /* margin-top:2rem; */
    bottom: 0.9rem;
    /* width: 100%; */
  }
  
  .active {
    background: var(--activeItem);
    margin-left: 0;
  }
  .active::before {
    content: "";
    width: 8px;
    height: 100%;
    background: var(--nyd-primary);
    margin-right: calc(1rem - 8px);
  }
  
  
  /* Tablets */
  @media screen and (max-width: 1200px) {
    .menuItem>span{
      display: none;
    }
    .logo{
      display: none;
    }
  }
  
  @media screen and (max-width: 768px) {
    .sidebar{
      position: fixed;
      z-index: 9;
      background: var(--nyd-secondary);
      width: 55%;
      padding-right: 1rem;
      height: 100%;
      color: var(--nyd-white);
    }
    .menuItem>span{
      display: block;
    }
    .logo{
      display: flex;
    }
    /* .menu .menuItem:last-child {
      position: relative;
      margin-top:2rem;
      bottom: 3rem;
    } */
    .bars{
      display: flex;
      position: fixed;
      top: 2rem;
      left:60%;
      background: var(--nyd-secondary);
      padding:10px;
      border-radius: 10px;
      color:var(--nyd-white);
      z-index: 9;
    }
    .close{
      left: -60%;
    }
  }
  